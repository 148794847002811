<template>
    <section>
        <div class="row mx-0 pt-3">
            <div class="col-8 position-relative">
                <div class=" overflow-auto custom-scroll position-relative px-0 bg-white br-t-8 pt-2" style="height:calc(100vh - 164px);">
                    <div v-for="(com,index) in comentarios" :key="index" class="row mx-0 px-3 py-3 border-bottom">
                        <img :src="com.foto" class="obj-cover border rounded-circle" width="32" height="32" />
                        <div class="col px-3">
                            <p class="text-general f-600 f-16">
                                {{ com.nombre }}
                            </p>
                            <p class="text-general f-14 mt-2">
                                {{ com.comentario }}
                            </p>
                            <p class="text-gris2 f-16 mt-2 f-500">
                                {{ formatearFecha(com.created_at,'DD MMM YYYY HH:mm a') }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="space-input py-3 bg-white z-1">
                    <div class="col-12 input-comments-wh-border">
                        <el-input v-model="comentario" placeholder="Comentar" :autosize="{ minRows: 1, maxRows: 4}" :class="`${comentario ? 'w-90' : 'w-100'} col-auto`" />
                        <i v-show="comentario" class="icon-enviar text-general-red ml-4 col-auto cr-pointer" @click="enviarComentario" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Proveedores from '@/services/proveedores/proveedores';
export default {
    data(){
        return{
            comentario: '',
            comentarios: [] 
        }
    },
    mounted(){
        this.listarComentarios()
    },
    methods: {
        async listarComentarios(){
            try {
                const { data } = await Proveedores.getComentariosProveedor(this.$route.params.id_proveedor)
                this.comentarios = data.data
            } catch (error){
                this.error_catch(error)
            }
        },
        async enviarComentario(){
            try {

                let params = {
                    id_proveedor: this.$route.params.id_proveedor,
                    comentario: this.comentario
                }

                const { data } = await Proveedores.crearComentarioProveedor(params)
                this.listarComentarios()
                this.comentario = ''
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.space-input{
    position: absolute;
    width: 96.5%;
    display: flex;
    border-radius: 12px 12px 0px 0;
    border: 1px solid #DFE4E8;
    bottom: 0;
}
</style>