<template>
    <section :class="`${verInforme ? 'px-4 pt-4' : 'pt-3'}`">
        <template v-if="!verInforme">
            <cargando v-if="cargando" />
            <p class="text-general f-18 f-600">
                Análisis del venta
            </p>
            <p class="text-general f-16 mt-3 mb-4">
                Llene el siguiente formulario para analizar el comportamiento de venta y tomar las decisiones de abastecimiento según los datos obtenidos.
            </p>
            <ValidationObserver ref="validacion">
                <div class="row mx-0 align-items-center">
                    <div class="col-4">
                        <p class="text-general f-12 pl-3">
                            Cedis
                        </p>
                        <div class="d-middle">
                            <div class="col px-0">
                                <ValidationProvider v-slot="{errors}" rules="required" name="Cedis">
                                    <el-select v-model="model.idCedis" class="w-100" size="large" @change="cambioCedis">
                                        <el-option
                                        v-for="cedi in cedis"
                                        :key="cedi.id"
                                        :label="cedi.nombre"
                                        :value="cedi.id"
                                        />
                                    </el-select>
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content" style="max-width:300px;">
                                    Selecciona cuál es el Cedis del que quieres hacer el análisis, teniendo en cuenta sus productos a la venta. Si seleccionas "Familia de Cedis" serán tenidos en cuenta todos los productos de todos los demás Cedis que comparten almacenamiento con el seleccionado.
                                </div>
                                <i class="icon-help-circle-outline ml-2 f-24 text-general" />
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="col-4 d-middle pt-3">
                        <template v-if="model.idCedis">
                            <el-checkbox v-model="model.selectFamilia" class="check-green mt-1" @change="controlConsultarFamiliaCedis($event)" />
                            <span class="f-16 mx-2 mt-1 text-general">Seleccionar la familia del cedis</span>
                            <el-popover ref="inventarioPopover" placement="bottom" class="mt-1" popper-class="p-0 br-12" trigger="click" width="350">
                                <div class="header-modal d-middle mb-3">
                                    <div class="col-auto">
                                        {{ !popoverCedisFamilia.cedisEsPadre ? 'Almacena inventario en otro cedis' : 'Almacena inventario en su propio cedis' }}
                                    </div>
                                    <i class="icon-cancel cr-pointer f-20 ml-auto" @click="$refs.inventarioPopover.doToggle()" />
                                </div>
                                <div class="row mx-0 justify-center text-general">
                                    <div class="col-10">
                                        {{ !popoverCedisFamilia.cedisEsPadre ? 'Este Cedis guarda su inventario en:' : 'Este Cedis guarda su inventario de:' }}
                                    </div>
                                    <div v-if="!popoverCedisFamilia.cedisEsPadre" class="col-10 f-600 f-16 mt-2">
                                        {{ popoverCedisFamilia.padre }}
                                    </div>
                                    <div v-if="!popoverCedisFamilia.cedisEsPadre" class="col-10 mt-3 mb-2">
                                        Otros Cedis que también lo guardan <br /> en el mismo:
                                    </div>
                                    <div v-if="popoverCedisFamilia.cedisEsPadre" class="col-10 mt-3 mb-2" />
                                    <template v-if="popoverCedisFamilia.hijos.length > 0">
                                        <div v-for="(item,index) in popoverCedisFamilia.hijos" :key="index" class="col-10 f-16 text-general f-500 mb-1">
                                            {{ item.nombre }}
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div class="col-10 text-general2 mr-5">
                                            <small>No registra</small>
                                        </div>
                                    </template>
                                </div>
                                <div class="row mx-0 pb-3" />
                                <span v-show="model.selectFamilia" slot="reference" class="text-general-red f-600">
                                    Ver
                                </span>
                            </el-popover>
                        </template>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-4">
                        <p class="text-general f-12 pl-3">
                            Meses de rotación
                        </p>
                        <div class="d-middle">
                            <div class="col px-0">
                                <ValidationProvider v-slot="{errors}" rules="required" name="Meses de rotación">
                                    <el-input v-model="model.cantidadMeses" class="w-100" placeholder="Cantidad" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content" style="max-width:300px;">
                                    Cantidad de meses sobre los cuales se hára el análisis de comportamiento de compra, definiendo cual es la rotacion de los productos
                                    para tomar desición de compra
                                </div>
                                <i class="icon-help-circle-outline ml-2 f-24 text-general" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-4">
                        <p class="text-general f-12 pl-3">
                            Días a comprar
                        </p>
                        <div class="d-middle">
                            <div class="col px-0">
                                <ValidationProvider v-slot="{errors}" rules="required" name="Días a comprar">
                                    <el-input v-model="model.cantidadDias" class="w-100" placeholder="Cantidad" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content" style="max-width:300px;">
                                    Cantidad de días que se espera se tenga abastecimiento para atender la demanda
                                </div>
                                <i class="icon-help-circle-outline ml-2 f-24 text-general" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-4">
                        <div class="d-middle">
                            <div class="col px-0">
                                <el-checkbox v-model="model.selectCarrito" class="check-green mt-1" />
                                <span class="f-16 mx-2 mt-1 text-general">Carrito de compras</span>
                            </div>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content" style="max-width:300px;">
                                    Selecciona carrito si quieres que sean tenidos en cuenta los productores que actualmente
                                    estan en intencion de compra de los {{$config.clientes}}, o no los selecciones si quieres que en el analisis
                                    solo sean tenidos en cuenta los pedidos confirmados
                                </div>
                                <i class="icon-help-circle-outline ml-2 f-24 text-general" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-4">
                        <p class="text-general f-12 pl-3">
                            Cantidad a ignorar
                        </p>
                        <div class="d-middle">
                            <div class="col px-0">
                                <ValidationProvider v-slot="{errors}" rules="required" name="Cantidad a ignorar">
                                    <el-input v-model="model.cantidadIgnorar" class="w-100" placeholder="Cantidad" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content" style="max-width:300px;">
                                    En análisis de compra te mostrara la cantidad de cada producto a comprar, digita el número 
                                    mínimo a tener en cuenta, para que los resultados excluyan las compras que son menores
                                </div>
                                <i class="icon-help-circle-outline ml-2 f-24 text-general" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-4">
                        <p class="text-general f-12 pl-3">
                            Proveedor
                        </p>
                        <div class="d-middle">
                            <div class="col px-0">
                                <ValidationProvider v-slot="{errors}" rules="required" name="Proveedor">
                                    <el-select v-model="model.idProveedor" class="w-100" size="large">
                                        <el-option
                                        v-for="proveedor in proveedores"
                                        :key="proveedor.id"
                                        :label="proveedor.nombre"
                                        :value="proveedor.id"
                                        />
                                    </el-select>
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <el-tooltip placement="bottom" effect="light">
                                <div slot="content" style="max-width:300px;">
                                    Selecciona cual es el proveedor sobre cual quieres hacer el analisis de compra
                                    Si seleccionas "Todos los proveedores" entonces el sistema tendra en cuenta todos los productos
                                    indiferente a cual es su proveedor favorito.
                                </div>
                                <i class="icon-help-circle-outline ml-2 f-24 text-general" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
            <div class="row mx-0 mt-4">
                <div class="col-auto">
                    <div class="btn-general px-4 f-14" @click="getComprasProveedores">
                        Generar informe
                    </div>
                </div>
            </div>
        </template>
        <informe-compra v-else :compras="compras" :parametros="model" @informeNuevo="irInformeNuevo" />
    </section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import Servicio from "~/services/proveedores/compras";
export default {
    components: {
        informeCompra: () => import('./components/informeCompra.vue')
    },
    data(){
        return {
            cargando: false,
            verInforme: false,
            compras: [],
            cedis: [],
            cedisFamilias: [],
            proveedores: [],
            popoverCedisFamilia: {
                cedisEsPadre: false,
                padre: '',
                hijos: [],
            },
            model: {
                idCedis: '',
                idProveedor: 0,
                cantidadMeses: '',
                cantidadDias: '',
                cantidadIgnorar: '',
                selectFamilia: false,
                selectCarrito: false,
            },
        }
    },
    computed: {
        ...mapGetters({
            filtroProveedorAplicado: 'proveedores/proveedores/filtroProveedorAplicado',
        }),
    },
    mounted(){
        this.setearFiltroProveedorAplicado();
        this.getInfoSelects();
    },
    methods: {
        async getInfoSelects(){
            try {
                const {data} = await Servicio.getInfoSelects();
                this.cedis = data.cedis;
                this.cedisFamilias = data.cedisFamilias;
                this.proveedores = data.proveedores;
            } catch (error){
                this.error_catch(error)
            }
        },
        async getComprasProveedores(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                this.cargando = true;
                const params = {
                    id_cedis: this.model.idCedis,
                    id_proveedor: this.model.idProveedor,
                    cantidad_meses: this.model.cantidadMeses,
                    cantidad_dias: this.model.cantidadDias,
                    carrito: this.model.selectCarrito == true ? 1 : 0,
                    cantidad_ignorar: this.model.cantidadIgnorar,
                    cedis_familia: this.model.selectFamilia == true ? 1 : 0,
                };
                const {data} = await Servicio.getComprasProveedores(params);
                if(data.compras.productos.length < 1){
                    return this.notificacion('Mensaje','No se encontraron registros','warning');
                }
                data.compras.productos.map(el =>{
                    el.ultima_compra_fecha = this.formatearFecha(el.ultima_compra_fecha,'D MMM Y');
                    el.ultimo_precio_fecha_actualizada = this.formatearFecha(el.ultimo_precio_fecha_actualizada,'D MMM Y');
                    el.ultima_compra_cantidad = this.agregarSeparadoresNumero(el.ultima_compra_cantidad);
                    el.ultimo_precio_valor = this.separadorNumeroDecimales(el.ultimo_precio_valor);
                    el.valor_unitario = this.separadorNumeroDecimales(el.valor_unitario);
                    el.valor_total = this.separadorNumeroDecimales(el.valor_total);
                    el.cantidad_existente = this.agregarSeparadoresNumero(el.cantidad_existente);
                    el.venta_sin_stock = parseInt(el.venta_sin_stock) === 1 ? 'SI' : 'NO';
                });
                this.compras = data.compras;
                if(this.model.idProveedor != '' && this.model.idProveedor != null && this.model.idProveedor != 0 && this.model.idProveedor != -1){
                    this.$store.commit('proveedores/proveedores/setFiltroProveedorAplicado', true);
                }
                let cedisSeleccionado = this.cedis.find((e) => e.id === this.model.idCedis)
                this.model.cedisSeleccionado = cedisSeleccionado.nombre;
                if(this.model.selectFamilia){
                    this.model.cedisFamilia = this.popoverCedisFamilia;
                }
                this.generarInforme();
            } catch (error){
                this.error_catch(error)
            } finally {
                this.cargando = false;
            }
        },
        controlConsultarFamiliaCedis(event){
            if(event){
                if(!this.model.idCedis)return;
                if(this.cedisFamilias.length < 1)return;
                this.mostrarFamiliaCedisSeleccionado();
            } else{
                this.limpiarPopover();
            }
        },
        mostrarFamiliaCedisSeleccionado(){
            let esPadre = this.cedisFamilias.find((element) => element.id_padre === this.model.idCedis);
            if(esPadre){
                this.popoverCedisFamilia.padre = esPadre.padre;
                this.popoverCedisFamilia.hijos = esPadre.hijos;
                this.popoverCedisFamilia.cedisEsPadre = true;
                return;
            }
            let contador = this.cedisFamilias.length;
            for(let i = 0; i < contador; i++){
                if(this.cedisFamilias[i].hijos.length > 0){
                    let esFamiliar = this.cedisFamilias[i].hijos.find((element) => element.id === this.model.idCedis);
                    if(esFamiliar){
                        this.popoverCedisFamilia.padre = this.cedisFamilias[i].padre;
                        this.popoverCedisFamilia.hijos = this.cedisFamilias[i].hijos;
                        this.popoverCedisFamilia.cedisEsPadre = false;
                        return;
                    }
                }
            }
        },
        setearFiltroProveedorAplicado(){
            this.$store.commit('proveedores/proveedores/setFiltroProveedorAplicado', false);
        },
        cambioCedis(){
            this.model.selectFamilia = false;
            this.limpiarPopover();
        },
        limpiarPopover(){
            this.popoverCedisFamilia.cedisEsPadre = false;
            this.popoverCedisFamilia.padre = '';
            this.popoverCedisFamilia.hijos = [];
        },
        generarInforme(){
            this.verInforme = !this.verInforme;
        },
        irInformeNuevo(){
            this.cargando = false;
            this.model.idCedis =  '';
            this.model.idProveedor = 0;
            this.model.cantidadMeses = '';
            this.model.cantidadDias = '';
            this.model.cantidadIgnorar = '';
            this.model.selectFamilia = false;
            this.model.selectCarrito = false;
            this.setearFiltroProveedorAplicado();
            this.limpiarPopover();
            this.verInforme = false;
        },
    }
}
</script>
<style lang="scss" scoped>
.header-modal{
    background-color: #F6F9FB;
    height: 60px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    color: #000000;
}
</style>