var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"custom-scroll overflow-auto",staticStyle:{"height":"calc(100vh - 86px)"}},[_c('div',{staticClass:"row mx-0"},[_c('i',{staticClass:"icon-back text-general f-22 mt-1 cr-pointer",on:{"click":function($event){return _vm.$router.back()}}}),_c('div',{staticClass:"col px-2"},[_vm._m(0),_c('div',{staticClass:"row mx-0"},[_vm._m(1),_c('div',{staticClass:"col-12 pl-0"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col pl-0"},[_vm._m(2),_vm._l((3),function(data,d1){return _c('div',{key:`compras-${d1}`,staticClass:"row mx-0 mb-3"},[_c('div',{staticClass:"col-12 px-0"},[_c('card-mision')],1)])})],2),_c('div',{staticClass:"col"},[_vm._m(3),_vm._l((3),function(data,d){return _c('div',{key:`compras-ped-${d}`,staticClass:"row mx-0 mb-3"},[_c('div',{staticClass:"col-12 px-0"},[_c('card-mision')],1)])})],2),_c('div',{staticClass:"col"},[_vm._m(4),_vm._l((3),function(data,d){return _c('div',{key:`compras-ped-${d}`,staticClass:"row mx-0 mb-3"},[_c('div',{staticClass:"col-12 px-0"},[_c('card-mision')],1)])})],2),_c('div',{staticClass:"col"},[_vm._m(5),_vm._l((3),function(data,d){return _c('div',{key:`compras-ped-${d}`,staticClass:"row mx-0 mb-3"},[_c('div',{staticClass:"col-12 px-0"},[_c('card-mision')],1)])})],2)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mx-0 title-category mb-4"},[_c('p',{staticClass:"col px-0 f-28 text-general f-500 mt-2"},[_vm._v(" Misiones de \"Septiembre\" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 pl-0"},[_c('div',{staticClass:"row mx-0 mb-4"},[_c('div',{staticClass:"col-auto px-0"},[_c('img',{staticClass:"br-8 obj-cover img-evento",attrs:{"src":"https://cdn.pixabay.com/photo/2016/08/03/09/03/universe-1566159__340.jpg","width":"360","height":"120"}})]),_c('div',{staticClass:"col px-xl-3 px-lg-3 px-md-0 px-sm-0 px-0"},[_c('p',{staticClass:"f-600 f-18 mt-2 text-general f-600"},[_vm._v(" Con cada misión lograda obtendrás mejores "),_c('br'),_vm._v(" recompensas ")]),_c('p',{staticClass:"f-18 mt-2 text-general f-600"},[_vm._v(" ¡Apresúrate y hazte con las gemas y monedas! ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-middle mb-2"},[_c('img',{attrs:{"src":"/img/gaming/i_bag_money.png","width":"40","height":"40"}}),_c('p',{staticClass:"col-auto px-2 text-general"},[_c('span',{staticClass:"f-600"},[_vm._v("Compras")]),_vm._v(" (Dinero) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-middle mb-2"},[_c('img',{attrs:{"src":"/img/gaming/i_checklist.png","width":"40","height":"40"}}),_c('p',{staticClass:"col-auto px-2 text-general"},[_c('span',{staticClass:"f-600"},[_vm._v("Compras")]),_vm._v(" (Cantidad de pedidos) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-middle mb-2"},[_c('img',{attrs:{"src":"/img/gaming/i_dinero.png","width":"40","height":"40"}}),_c('p',{staticClass:"col-auto px-2 text-general"},[_c('span',{staticClass:"f-600"},[_vm._v("Compras")]),_vm._v(" (Cantidad de pedidos) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-middle mb-2"},[_c('img',{attrs:{"src":"/img/gaming/i_ventas_cantidad.png","width":"40","height":"40"}}),_c('p',{staticClass:"col-auto px-2 text-general"},[_c('span',{staticClass:"f-600"},[_vm._v("Compras")]),_vm._v(" (Cantidad de pedidos) ")])])
}]

export { render, staticRenderFns }